// IMPORTS
import './App.scss';

import Home from './routes/Home/Home';
import Login from './routes/Login/Login';
import Admin from './routes/Admin/Admin';

import { Routes, Route, Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import useAuthToken from './hooks/useAuthToken';


// COMPONENT
function App() {
  // Security
  const [authToken, setAuthToken] = useAuthToken();

  // States and Variables
  const currentPage = useLocation();
  const defaultNavLink = {
    link: '/admin',
    title: 'Admin Dashboard'
  };
  const [navLink, setNavLink] = useState(defaultNavLink);


  // Listeners
  useEffect(function () {
    switch (currentPage.pathname) {
      case "/login":
      case "/admin":
        setNavLink({
          link: "/",
          title: "Home"
        });
        break;
      default:
        setNavLink(defaultNavLink);
        break;
    }
  }, [currentPage]);


  // Component
  return (
    <div className="App">
      <nav>
        <div className='App-header'>
          <h1>Plex Request</h1>
        </div>
        <div className='App-nav'>
          <Link to={navLink.link}><button className='global-btn-success'>{navLink.title}</button></Link>
        </div>
      </nav>
      <main>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/login' element={<Login />}></Route>
          <Route exact path='/admin' element={<Admin />}></Route>
        </Routes>
      </main>
    </div>
  );
}

export default App;
