// IMPORTS
import { useState } from 'react';


// COMPONENT
function useAuthToken() {
    var cookiename = "PlexRequestAuthToken";
    var tokenlength = 1024; //complexity of the authentication token

    function generateRandomCode () {
        var result = '';
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = 0; i < tokenlength; i++) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }

    const getAuthToken = function (initialLoad=false) {
        var cookieArr = document.cookie.split(";");
        for(var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if(cookiename == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]); //provides current authentication cookie
            }
        }
        saveAuthToken(initialLoad); //creates new authentication cookie if it does not exist
    }

    const saveAuthToken = function (initialLoad) {
        var value = generateRandomCode();
        document.cookie = cookiename + "=" + value + ";path=/;";
        if (initialLoad) {
            return value;
        } else {
            setAuthToken(value);
        }
    }

    const [authToken, setAuthToken] = useState(getAuthToken(true));

    const clearAuthToken = function () {
        document.cookie = cookiename + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
        setAuthToken(null);
    }

    return [authToken, saveAuthToken, clearAuthToken];
}

export default useAuthToken;